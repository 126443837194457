<template>
  <v-list-group
    v-if="node.create && hasAnyVisibleChild"
    no-action
    eager
    :value="active"
    :prepend-icon="node.icon"
  >
    <template v-slot:activator>
      <v-list-item-title>{{node.label}}</v-list-item-title>
    </template>

    <childLink
      @activeChange="activeChange"
      v-for="(child, childIndex) in children"
      :is-child="true"
      :node="child"
      :key="childIndex"
    >
    </childLink>
  </v-list-group>
</template>

<script>
import node from './node.js'
export default {
  mixins: [node],
  data() {
    return {
      active: false,
    }
  },
  methods: {
    activeChange(status) {
      this.active = status
    },
  },
  computed: {
    children() {
      if(!Array.isArray(this.node.group)) return
      return this.node.group
    },
    hasAnyVisibleChild() {
      return this.children.some(node => node.create === true)
    },
  },
  components: {
    childLink: () => import('./link.vue')
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>